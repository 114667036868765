import React, { Component } from 'react'
import classNames from 'classnames'
import posed from 'react-pose'
import { tween } from 'popmotion'
import { Link } from './Link'
import version from '../utils/config'

const TopBar = posed.div({
  open: {
    rotate: 0,
    originY: '50%',
    originX: '-600%',
    staggerChildren: 60,
    opacity: 1,
    beforeChildren: true,
    transition: tween,
  },
  closed: {
    rotate: -11,
    originY: '50%',
    originX: '-600%',
    opacity: 0,
    transition: tween,
  },
})

const MobileTopBar = posed.div({
  open: {
    rotate: 0,
    originY: '400%',
    originX: '50%',
    staggerChildren: 60,
    opacity: 1,
    beforeChildren: true,
    transition: tween,
  },
  closed: {
    rotate: -11,
    originY: '400%',
    originX: '50%',
    opacity: 0,
    transition: tween,
  },
})

const NavItem = posed.li({
  open: { opacity: 1, y: '0%' },
  closed: { opacity: 0, y: '50%' },
})

const TangoMenu = posed.div({
  open: {
    opacity: 1,
    x: '0px',
  },
  closed: {
    opacity: 0,
    x: '100px',
  },
})

const SocialLinks = posed.div({
  open: {
    opacity: 1,
    x: '0px',
    transition: tween,
  },
  closed: {
    opacity: 0,
    x: '-100px',
    transition: tween,
  },
})

const SocialLinksMobile = posed.div({
  open: {
    opacity: 1,
    y: '0%',
    transition: tween,
  },
  closed: {
    opacity: 0,
    y: '100%',
    transition: tween,
  },
})

const NavDesktop = ({ isOpen, navItems, onClick }) => (
  <TopBar
    id="nav"
    pose={isOpen ? 'open' : 'closed'}
    className={isOpen ? 'ng-nav-open' : 'ng-nav-closed'}
  >
    <div className="content">
      <div className="ng-menu-version-container">
        <div className="ng-version-badge">v{version}</div>
      </div>
      <div className="ng-subhero-logo">
        <Link to="/" className="ng-footer-logo">
          <svg
            id="Layer_1"
            className="ng-logo-with-blocks"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 123.37 142.45"
          >
            <title>NEXTGEN Code Company</title>
            <g id="block1">
              <polygon
                className="cls-1"
                points="0 106.84 0 130.58 20.56 142.45 20.56 118.71 0 106.84"
              />
              <polygon
                className="cls-2"
                points="41.12 106.84 20.56 118.71 20.56 142.45 41.12 130.58 41.12 106.84"
              />
              <polygon
                className="cls-3"
                points="0.12 106.36 20.68 94.48 41.25 106.36 20.68 118.23 0.12 106.36"
              />
            </g>
            <g id="block2">
              <polygon
                className="cls-4"
                points="0 83.1 0 106.84 20.56 118.71 20.56 94.97 0 83.1"
              />
              <polygon
                className="cls-5"
                points="41.12 106.84 20.56 118.71 20.56 94.97 41.12 83.1 41.12 106.84"
              />
              <polygon
                className="cls-6"
                points="0.12 82.36 20.68 70.48 41.25 82.36 20.68 94.23 0.12 82.36"
              />
            </g>
            <g id="block3">
              <polygon
                className="cls-7"
                points="0 59.36 0 83.1 20.56 94.97 20.56 71.23 0 59.36"
              />
              <polygon
                className="cls-8"
                points="0.12 59.36 20.68 47.48 41.25 59.36 20.68 71.23 0.12 59.36"
              />
              <polygon
                className="cls-9"
                points="41.12 59.36 20.56 71.23 20.56 94.97 41.12 83.1 41.12 59.36"
              />
            </g>
            <g id="block4">
              <polygon
                className="cls-10"
                points="0 35.6 0 59.36 20.58 71.25 20.58 47.48 0 35.6"
              />
              <polygon
                className="cls-11"
                points="0 35.61 20.56 23.74 41.12 35.61 20.56 47.48 0 35.61"
              />
              <polygon
                className="cls-12"
                points="41.37 35.61 20.81 47.48 20.81 71.23 41.37 59.36 41.37 35.61"
              />
            </g>
            <g id="block5">
              <polygon
                className="cls-13"
                points="20.56 47.48 20.56 71.23 41.12 83.1 41.12 59.36 20.56 47.48"
              />
              <polygon
                className="cls-14"
                points="20.56 47.48 41.12 35.61 61.69 47.48 41.12 59.36 20.56 47.48"
              />
              <polygon
                className="cls-15"
                points="61.69 71.23 41.12 83.1 41.12 59.36 61.69 47.49 61.69 71.23"
              />
            </g>
            <g id="block6">
              <polygon
                className="cls-16"
                points="41.12 59.36 41.12 83.1 61.69 94.97 61.69 71.23 41.12 59.36"
              />
              <polygon
                className="cls-17"
                points="41.12 59.36 61.68 47.48 82.25 59.36 61.68 71.23 41.12 59.36"
              />
              <polygon
                className="cls-18"
                points="82.25 83.1 61.68 94.97 61.68 71.23 82.25 59.36 82.25 83.1"
              />
            </g>
            <g id="block7">
              <polygon
                className="cls-19"
                points="61.69 71.23 61.69 94.97 82.25 106.84 82.25 83.1 61.69 71.23"
              />
              <polygon
                className="cls-20"
                points="102.81 94.97 82.25 106.84 82.25 83.1 102.81 71.23 102.81 94.97"
              />
              <polygon
                className="cls-21"
                points="61.68 71.25 82.25 59.38 102.81 71.25 82.25 83.12 61.68 71.25"
              />
            </g>
            <g id="block8">
              <polygon
                className="cls-22"
                points="123.37 83.1 102.81 94.97 102.81 118.71 123.37 106.84 123.37 83.1"
              />
              <polygon
                className="cls-23"
                points="82.25 83.1 82.25 106.84 102.81 118.71 102.81 94.97 82.25 83.1"
              />
              <polygon
                className="cls-24"
                points="82.25 83.1 102.81 71.23 123.37 83.1 102.81 94.97 82.25 83.1"
              />
            </g>
            <g id="block9">
              <polygon
                className="cls-25"
                points="123.37 83.1 102.81 94.97 102.81 71.23 123.37 59.36 123.37 83.1"
              />
              <polygon
                className="cls-26"
                points="82.25 59.36 82.25 83.1 102.81 94.97 102.81 71.23 82.25 59.36"
              />
              <polygon
                className="cls-27"
                points="82.25 59.36 102.81 47.48 123.37 59.36 102.81 71.23 82.25 59.36"
              />
            </g>
            <g id="block10">
              <polygon
                className="cls-28"
                points="123.37 35.61 102.81 47.48 102.81 71.23 123.37 59.36 123.37 35.61"
              />
              <polygon
                className="cls-29"
                points="82.25 35.61 82.25 59.36 102.81 71.23 102.81 47.48 82.25 35.61"
              />
              <polygon
                className="cls-30"
                points="82.25 35.6 102.81 23.72 123.37 35.6 102.81 47.47 82.25 35.6"
              />
            </g>
            <g id="block11">
              <polygon
                className="cls-31"
                points="123.37 35.61 102.81 47.48 102.81 23.74 123.37 11.87 123.37 35.61"
              />
              <polygon
                className="cls-32"
                points="82.25 11.87 82.25 35.61 102.81 47.48 102.81 23.74 82.25 11.87"
              />
              <polygon
                className="cls-33"
                points="82.25 11.87 102.81 0 123.37 11.87 102.81 23.74 82.25 11.87"
              />
            </g>
          </svg>
        </Link>
      </div>
      <div className="container-prLink">
        <ul className="page-home">
          {navItems.map(({ url, name, menu_class, anchor }) => (
            <NavItem className={`${menu_class}`} key={name}>
              <>
                {url && (
                  <Link className="ng-menu-link" to={url}>
                    {name}
                  </Link>
                )}
                {anchor && (
                  <a className="ng-menu-link" href={anchor} onClick={onClick}>
                    {name}
                  </a>
                )}
              </>
            </NavItem>
          ))}

          <TangoMenu className="ng-tango-menu">
            <div className="ng-tango-bubbles-home ng-tango-bubble">Hello</div>
            <div className="ng-tango-bubbles-contact ng-tango-bubble">
              My team loves meeting new people
            </div>
            <div className="ng-tango-bubbles-services ng-tango-bubble">
              See the capabilities of my team
            </div>
            <div className="ng-tango-bubbles-about ng-tango-bubble">
              Learn about me and my team
            </div>
            <div className="ng-tango-bubbles-projects ng-tango-bubble">
              See what me and my team have done
            </div>
            <div className="ng-tango-bubbles-careers ng-tango-bubble">
              Work for me ;)
            </div>
            <div className="ng-bubble-small" />
            <div className="ng-bubble-medium" />

            <div className="ng-bubble-small-alt1" />
            <div className="ng-bubble-medium-alt1" />
            <img
              alt="tango"
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242652/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/tango.svg"
            />
          </TangoMenu>
          <SocialLinks className="social-links">
            <a
              href="https://www.facebook.com/NextGenCodeCompany/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/tylerholden/image/upload/v1557242631/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/facebook_social_icon.svg"
                alt="NEXTGEN facebook"
              />
            </a>
            <a
              href="https://twitter.com/NextGenCodeCo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/tylerholden/image/upload/v1557242643/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/twitter_social_icon.svg"
                alt="NEXTGEN twitter"
              />
            </a>
            <a
              href="https://www.instagram.com/nextgencodeco/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/tylerholden/image/upload/v1557242618/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/instagram_social_icon.svg"
                alt="NEXTGEN instagram"
              />
            </a>
            <a
              href="https://www.pinterest.com/nextgencodecompany/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/tylerholden/image/upload/v1557242622/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/pinterest_social_icon.svg"
                alt="NEXTGEN pinterest"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/nextgen-code-company"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/tylerholden/image/upload/v1557242645/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/linked_in_social_icon.svg"
                alt="NEXTGEN linkedin"
              />
            </a>
          </SocialLinks>
        </ul>
      </div>
    </div>
  </TopBar>
)

const NavMobile = ({ isOpen, navItems, onClick }) => (
  <MobileTopBar
    id="nav-mobile"
    pose={isOpen ? 'open' : 'closed'}
    className={isOpen ? 'ng-nav-open' : 'ng-nav-closed'}
  >
    <div className="ng-mobile-menu-version-container">
      <div className="ng-version-badge">v{version}</div>
    </div>
    <div className="wrapper">
      <div className="container container-linkPr">
        <div className="ng-subhero-logo">
          <Link to="/" className="ng-footer-logo">
            <svg
              id="Layer_1"
              className="ng-logo-with-blocks"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 123.37 142.45"
            >
              <title>NEXTGEN Code Company</title>
              <g id="block1">
                <polygon
                  className="cls-1"
                  points="0 106.84 0 130.58 20.56 142.45 20.56 118.71 0 106.84"
                />
                <polygon
                  className="cls-2"
                  points="41.12 106.84 20.56 118.71 20.56 142.45 41.12 130.58 41.12 106.84"
                />
                <polygon
                  className="cls-3"
                  points="0.12 106.36 20.68 94.48 41.25 106.36 20.68 118.23 0.12 106.36"
                />
              </g>
              <g id="block2">
                <polygon
                  className="cls-4"
                  points="0 83.1 0 106.84 20.56 118.71 20.56 94.97 0 83.1"
                />
                <polygon
                  className="cls-5"
                  points="41.12 106.84 20.56 118.71 20.56 94.97 41.12 83.1 41.12 106.84"
                />
                <polygon
                  className="cls-6"
                  points="0.12 82.36 20.68 70.48 41.25 82.36 20.68 94.23 0.12 82.36"
                />
              </g>
              <g id="block3">
                <polygon
                  className="cls-7"
                  points="0 59.36 0 83.1 20.56 94.97 20.56 71.23 0 59.36"
                />
                <polygon
                  className="cls-8"
                  points="0.12 59.36 20.68 47.48 41.25 59.36 20.68 71.23 0.12 59.36"
                />
                <polygon
                  className="cls-9"
                  points="41.12 59.36 20.56 71.23 20.56 94.97 41.12 83.1 41.12 59.36"
                />
              </g>
              <g id="block4">
                <polygon
                  className="cls-10"
                  points="0 35.6 0 59.36 20.58 71.25 20.58 47.48 0 35.6"
                />
                <polygon
                  className="cls-11"
                  points="0 35.61 20.56 23.74 41.12 35.61 20.56 47.48 0 35.61"
                />
                <polygon
                  className="cls-12"
                  points="41.37 35.61 20.81 47.48 20.81 71.23 41.37 59.36 41.37 35.61"
                />
              </g>
              <g id="block5">
                <polygon
                  className="cls-13"
                  points="20.56 47.48 20.56 71.23 41.12 83.1 41.12 59.36 20.56 47.48"
                />
                <polygon
                  className="cls-14"
                  points="20.56 47.48 41.12 35.61 61.69 47.48 41.12 59.36 20.56 47.48"
                />
                <polygon
                  className="cls-15"
                  points="61.69 71.23 41.12 83.1 41.12 59.36 61.69 47.49 61.69 71.23"
                />
              </g>
              <g id="block6">
                <polygon
                  className="cls-16"
                  points="41.12 59.36 41.12 83.1 61.69 94.97 61.69 71.23 41.12 59.36"
                />
                <polygon
                  className="cls-17"
                  points="41.12 59.36 61.68 47.48 82.25 59.36 61.68 71.23 41.12 59.36"
                />
                <polygon
                  className="cls-18"
                  points="82.25 83.1 61.68 94.97 61.68 71.23 82.25 59.36 82.25 83.1"
                />
              </g>
              <g id="block7">
                <polygon
                  className="cls-19"
                  points="61.69 71.23 61.69 94.97 82.25 106.84 82.25 83.1 61.69 71.23"
                />
                <polygon
                  className="cls-20"
                  points="102.81 94.97 82.25 106.84 82.25 83.1 102.81 71.23 102.81 94.97"
                />
                <polygon
                  className="cls-21"
                  points="61.68 71.25 82.25 59.38 102.81 71.25 82.25 83.12 61.68 71.25"
                />
              </g>
              <g id="block8">
                <polygon
                  className="cls-22"
                  points="123.37 83.1 102.81 94.97 102.81 118.71 123.37 106.84 123.37 83.1"
                />
                <polygon
                  className="cls-23"
                  points="82.25 83.1 82.25 106.84 102.81 118.71 102.81 94.97 82.25 83.1"
                />
                <polygon
                  className="cls-24"
                  points="82.25 83.1 102.81 71.23 123.37 83.1 102.81 94.97 82.25 83.1"
                />
              </g>
              <g id="block9">
                <polygon
                  className="cls-25"
                  points="123.37 83.1 102.81 94.97 102.81 71.23 123.37 59.36 123.37 83.1"
                />
                <polygon
                  className="cls-26"
                  points="82.25 59.36 82.25 83.1 102.81 94.97 102.81 71.23 82.25 59.36"
                />
                <polygon
                  className="cls-27"
                  points="82.25 59.36 102.81 47.48 123.37 59.36 102.81 71.23 82.25 59.36"
                />
              </g>
              <g id="block10">
                <polygon
                  className="cls-28"
                  points="123.37 35.61 102.81 47.48 102.81 71.23 123.37 59.36 123.37 35.61"
                />
                <polygon
                  className="cls-29"
                  points="82.25 35.61 82.25 59.36 102.81 71.23 102.81 47.48 82.25 35.61"
                />
                <polygon
                  className="cls-30"
                  points="82.25 35.6 102.81 23.72 123.37 35.6 102.81 47.47 82.25 35.6"
                />
              </g>
              <g id="block11">
                <polygon
                  className="cls-31"
                  points="123.37 35.61 102.81 47.48 102.81 23.74 123.37 11.87 123.37 35.61"
                />
                <polygon
                  className="cls-32"
                  points="82.25 11.87 82.25 35.61 102.81 47.48 102.81 23.74 82.25 11.87"
                />
                <polygon
                  className="cls-33"
                  points="82.25 11.87 102.81 0 123.37 11.87 102.81 23.74 82.25 11.87"
                />
              </g>
            </svg>
          </Link>
        </div>
        <ul className="mobile-nav">
          {navItems.map(({ url, name, menu_class, anchor }) => (
            <NavItem className={`${menu_class}`} key={name}>
              <>
                {url && (
                  <Link className="ng-menu-link" to={url}>
                    {name}
                  </Link>
                )}
                {anchor && (
                  <a className="ng-menu-link" href={anchor} onClick={onClick}>
                    {name}
                  </a>
                )}
              </>
            </NavItem>
          ))}
        </ul>
        <SocialLinksMobile className="social-links social-links-mobile">
          <a
            href="https://www.facebook.com/NextGenCodeCompany/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242631/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/facebook_social_icon.svg"
              alt="NEXTGEN facebook"
            />
          </a>
          <a
            href="https://twitter.com/NextGenCodeCo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242643/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/twitter_social_icon.svg"
              alt="NEXTGEN twitter"
            />
          </a>
          <a
            href="https://www.instagram.com/nextgencodeco/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242618/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/instagram_social_icon.svg"
              alt="NEXTGEN instagram"
            />
          </a>
          <a
            href="https://www.pinterest.com/nextgencodecompany/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242622/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/pinterest_social_icon.svg"
              alt="NEXTGEN pinterest"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/nextgen-code-company"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242645/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/linked_in_social_icon.svg"
              alt="NEXTGEN linkedin"
            />
          </a>
        </SocialLinksMobile>
      </div>
    </div>
  </MobileTopBar>
)

const navLinks = [
  { url: '/', name: 'Home', menu_class: 'ng-menu-link-home' },
  { url: '/about/', name: 'About', menu_class: 'ng-menu-link-about' },
  { url: '/services/', name: 'Services', menu_class: 'ng-menu-link-services' },
  { url: '/projects/', name: 'Projects', menu_class: 'ng-menu-link-projects' },
  { url: '/careers/', name: 'Careers', menu_class: 'ng-menu-link-careers' },
  {
    url: '/contact',
    name: 'Contact Us',
    menu_class: 'ng-menu-link-contact',
  },
]

class Nav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isClosed: true,
      isScrolling: false,
      isOpenDesktop: false,
      isOpenMobile: false,
      mobileNavX: false,
      navX: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (window.scrollY > 50) {
      this.setState({
        isScrolling: true,
      })
    } else {
      this.setState({
        isScrolling: false,
      })
    }
  }

  render() {
    const navButtonStyles = classNames({
      scroll: this.state.isScrolling,
      active: this.state.navX,
    })

    const handleNavClick = () => {
      this.setState(prevState => ({
        isOpenDesktop: !prevState.isOpenDesktop,
        navX: !prevState.navX,
      }))
    }

    const handleMobileNav = () => {
      this.setState(prevState => ({
        isOpenMobile: !prevState.isOpenMobile,
        navX: !prevState.navX,
      }))
    }

    return (
      <React.Fragment>
        <div id="nav-btn" onClick={handleNavClick} className={navButtonStyles}>
          <div className="text ng-menu-button-lg">MENU</div>
        </div>

        <div
          id="nav-btn-mobile"
          onClick={handleMobileNav}
          className={navButtonStyles}
        >
          <div className="text ng-menu-button-lg">MENU</div>
        </div>

        <NavDesktop
          isOpen={this.state.isOpenDesktop}
          navItems={navLinks}
          onClick={handleNavClick}
        />

        <NavMobile
          isOpen={this.state.isOpenMobile}
          navItems={navLinks}
          onClick={handleMobileNav}
        />
      </React.Fragment>
    )
  }
}

export default Nav
