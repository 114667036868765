import React from 'react'
// import { Link } from './Link'
import version from '../utils/config'

const Header = () => {
  return (
    <div className="ng-version-header-container">
      <div className="ng-version-badge">v{version}</div>
    </div>
  )
}

export default Header
