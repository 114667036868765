import React from 'react'
import version from '../utils/config'

const FooterV2 = () => {
  const [currentYear] = React.useState(new Date().getFullYear())
  return (
    <div className="ng-footer-container">
      <div className="ng-footer footer">
        <div className="ng-version-badge">v{version}</div>
        <img
          className="ng-shopify-partner"
          src="https://res.cloudinary.com/tylerholden/image/upload/v1557242618/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/Shopify_Partner.svg"
          alt="Shopify Partner - Design and Development"
        />

        <a className="ng-footer-link" href="/">
          HOME
        </a>
        <span className="ng-footer-link-span">/</span>
        <a className="ng-footer-link" href="/about">
          ABOUT
        </a>
        <span className="ng-footer-link-span">/</span>
        <a className="ng-footer-link" href="/services">
          SERVICES
        </a>
        <span className="ng-footer-link-span">/</span>
        <a className="ng-footer-link" href="/projects">
          PROJECTS
        </a>
        <span className="ng-footer-link-span">/</span>
        <a className="ng-footer-link" href="/careers">
          CAREERS
        </a>

        <div className="ng-footer-divider" />

        <div className="ng-footer-social-links">
          <a
            href="https://www.facebook.com/NextGenCodeCompany/"
            target="_blank"
            rel="noopener noreferrer"
            className="ng-footer-social-icon"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242638/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/facebook_social_icon_white.svg"
              alt="NEXTGEN's Facebook Page"
            />
          </a>
          <a
            href="https://twitter.com/NextGenCodeCo"
            target="_blank"
            rel="noopener noreferrer"
            className="ng-footer-social-icon"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242643/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/twitter_social_icon_white.svg"
              alt="NEXTGEN's Twitter Page"
            />
          </a>
          <a
            href="https://www.instagram.com/nextgencodeco/"
            target="_blank"
            rel="noopener noreferrer"
            className="ng-footer-social-icon"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242618/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/instagram_social_icon_white.svg"
              alt="NEXTGEN's Instgram Page"
            />
          </a>
          <a
            href="https://www.pinterest.com/nextgencodecompany/"
            target="_blank"
            rel="noopener noreferrer"
            className="ng-footer-social-icon"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242646/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/pinterest_social_icon_white.svg"
              alt="NEXTGEN's Pinterest Page"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/nextgen-code-company"
            target="_blank"
            rel="noopener noreferrer"
            className="ng-footer-social-icon"
          >
            <img
              src="https://res.cloudinary.com/tylerholden/image/upload/v1557242632/NextGen%20Site/NextGen%20Site%20Assets%20on%20Cloudinary/social%20icons/linked_in_social_icon_white.svg"
              alt="NEXTGEN's LinkedIn Page"
            />
          </a>
        </div>
        <a href="/" className="ng-footer-logo">
          <svg
            id="Layer_1"
            className="ng-logo-with-blocks"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 123.37 142.45"
          >
            <title>NEXTGEN Code Company</title>
            <g id="block1">
              <polygon
                className="cls-1"
                points="0 106.84 0 130.58 20.56 142.45 20.56 118.71 0 106.84"
              />
              <polygon
                className="cls-2"
                points="41.12 106.84 20.56 118.71 20.56 142.45 41.12 130.58 41.12 106.84"
              />
              <polygon
                className="cls-3"
                points="0.12 106.36 20.68 94.48 41.25 106.36 20.68 118.23 0.12 106.36"
              />
            </g>
            <g id="block2">
              <polygon
                className="cls-4"
                points="0 83.1 0 106.84 20.56 118.71 20.56 94.97 0 83.1"
              />
              <polygon
                className="cls-5"
                points="41.12 106.84 20.56 118.71 20.56 94.97 41.12 83.1 41.12 106.84"
              />
              <polygon
                className="cls-6"
                points="0.12 82.36 20.68 70.48 41.25 82.36 20.68 94.23 0.12 82.36"
              />
            </g>
            <g id="block3">
              <polygon
                className="cls-7"
                points="0 59.36 0 83.1 20.56 94.97 20.56 71.23 0 59.36"
              />
              <polygon
                className="cls-8"
                points="0.12 59.36 20.68 47.48 41.25 59.36 20.68 71.23 0.12 59.36"
              />
              <polygon
                className="cls-9"
                points="41.12 59.36 20.56 71.23 20.56 94.97 41.12 83.1 41.12 59.36"
              />
            </g>
            <g id="block4">
              <polygon
                className="cls-10"
                points="0 35.6 0 59.36 20.58 71.25 20.58 47.48 0 35.6"
              />
              <polygon
                className="cls-11"
                points="0 35.61 20.56 23.74 41.12 35.61 20.56 47.48 0 35.61"
              />
              <polygon
                className="cls-12"
                points="41.37 35.61 20.81 47.48 20.81 71.23 41.37 59.36 41.37 35.61"
              />
            </g>
            <g id="block5">
              <polygon
                className="cls-13"
                points="20.56 47.48 20.56 71.23 41.12 83.1 41.12 59.36 20.56 47.48"
              />
              <polygon
                className="cls-14"
                points="20.56 47.48 41.12 35.61 61.69 47.48 41.12 59.36 20.56 47.48"
              />
              <polygon
                className="cls-15"
                points="61.69 71.23 41.12 83.1 41.12 59.36 61.69 47.49 61.69 71.23"
              />
            </g>
            <g id="block6">
              <polygon
                className="cls-16"
                points="41.12 59.36 41.12 83.1 61.69 94.97 61.69 71.23 41.12 59.36"
              />
              <polygon
                className="cls-17"
                points="41.12 59.36 61.68 47.48 82.25 59.36 61.68 71.23 41.12 59.36"
              />
              <polygon
                className="cls-18"
                points="82.25 83.1 61.68 94.97 61.68 71.23 82.25 59.36 82.25 83.1"
              />
            </g>
            <g id="block7">
              <polygon
                className="cls-19"
                points="61.69 71.23 61.69 94.97 82.25 106.84 82.25 83.1 61.69 71.23"
              />
              <polygon
                className="cls-20"
                points="102.81 94.97 82.25 106.84 82.25 83.1 102.81 71.23 102.81 94.97"
              />
              <polygon
                className="cls-21"
                points="61.68 71.25 82.25 59.38 102.81 71.25 82.25 83.12 61.68 71.25"
              />
            </g>
            <g id="block8">
              <polygon
                className="cls-22"
                points="123.37 83.1 102.81 94.97 102.81 118.71 123.37 106.84 123.37 83.1"
              />
              <polygon
                className="cls-23"
                points="82.25 83.1 82.25 106.84 102.81 118.71 102.81 94.97 82.25 83.1"
              />
              <polygon
                className="cls-24"
                points="82.25 83.1 102.81 71.23 123.37 83.1 102.81 94.97 82.25 83.1"
              />
            </g>
            <g id="block9">
              <polygon
                className="cls-25"
                points="123.37 83.1 102.81 94.97 102.81 71.23 123.37 59.36 123.37 83.1"
              />
              <polygon
                className="cls-26"
                points="82.25 59.36 82.25 83.1 102.81 94.97 102.81 71.23 82.25 59.36"
              />
              <polygon
                className="cls-27"
                points="82.25 59.36 102.81 47.48 123.37 59.36 102.81 71.23 82.25 59.36"
              />
            </g>
            <g id="block10">
              <polygon
                className="cls-28"
                points="123.37 35.61 102.81 47.48 102.81 71.23 123.37 59.36 123.37 35.61"
              />
              <polygon
                className="cls-29"
                points="82.25 35.61 82.25 59.36 102.81 71.23 102.81 47.48 82.25 35.61"
              />
              <polygon
                className="cls-30"
                points="82.25 35.6 102.81 23.72 123.37 35.6 102.81 47.47 82.25 35.6"
              />
            </g>
            <g id="block11">
              <polygon
                className="cls-31"
                points="123.37 35.61 102.81 47.48 102.81 23.74 123.37 11.87 123.37 35.61"
              />
              <polygon
                className="cls-32"
                points="82.25 11.87 82.25 35.61 102.81 47.48 102.81 23.74 82.25 11.87"
              />
              <polygon
                className="cls-33"
                points="82.25 11.87 102.81 0 123.37 11.87 102.81 23.74 82.25 11.87"
              />
            </g>
          </svg>
        </a>
        <div className="ng-footer-copyright">
          NEXTGEN Code Company © {currentYear}
          <br />
          <br />
          Dallas, TX &#8226; Lubbock, TX &#8226; Corpus Christi, TX &#8226;
          Austin, TX
          <br />
          <br />
          <a href="mailto:tango@nextgencode.io">tango@nextgencode.io</a>
        </div>
      </div>
    </div>
  )
}

export default FooterV2
